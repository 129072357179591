import { configureStore } from '@reduxjs/toolkit';
import rootSlice from './slices/rootSlice.js';
import { useDispatch, useSelector } from 'react-redux';

const store = configureStore({
  reducer: {
    root: rootSlice,
  },
});

export const useAppDispatch = () => useDispatch();

export const useAppSelector = useSelector;
export default store;
