import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  AssetsList: [],
};

const rootSlice = createSlice({
  name: "root",
  initialState,
  reducers: {
    setAssetsList: (state, action) => {
      state.AssetsList = action.payload;
    },
  },
});

export const { setAssetsList } = rootSlice.actions;

export default rootSlice.reducer;
