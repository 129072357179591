import { Drawer } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styles from '../styles/MobileOrderDrawer.module.scss';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import { colourStylesCurrency } from './SelectCurrencyStyles';
import altIcon from '../imgs/altIcon.svg'
import secureLocalStorage from 'react-secure-storage';
import moment from 'moment';
import assetsLogo from './assetsLogo.json'
import assetsLogo12px from './assetsLogo12px.json'
import { useAppSelector } from "../redux/store.js";

export default function MobileOrderDrawer({currentRecords, open, toggleDrawer,theme,activeOrder,eurToUsd,account }) {
    const { t } = useTranslation()
    const [accountType, setAccountType] = useState('Real')
    const [marketPrice , setMarketPrice] = useState('');
    const AssetsList = useAppSelector((state) => state.root.AssetsList);

    useEffect(()=>{
        if(activeOrder && activeOrder.MarketPrice){
            setMarketPrice(currentRecords.find(el=> el.Id === activeOrder.Id).MarketPrice)
        }
    },[currentRecords,activeOrder])
    const getLiquidationPrice = (row) => {
        const user = secureLocalStorage.getItem("user");
        const currency = user?.Currency || "USD"; // Предполагаем, что по умолчанию USD
        const currencyFactor = currency === "EUR" ? eurToUsd : 1;
        const symbol = currency === "EUR" ? "€" : "$";
        
        const startPrice = parseFloat(row?.StartPrice) * currencyFactor;
        const leverage = parseFloat(row?.Leverage);
    
        // Если кредитное плечо равно 1, ликвидационная цена не применяется
        if (leverage === 1) {
            return '-- --';
        }
    
        let liquidationPrice;
    
        if (row?.Status === 'BUY') {
            // Формула для длинной позиции (BUY)
            liquidationPrice = startPrice * (1 - 1 / leverage);
        } else {
            // Формула для короткой позиции (SELL)
            liquidationPrice = startPrice * (1 + 1 / leverage);
        }
    
        // Форматируем результат до 4 знаков после запятой
        return `${liquidationPrice.toFixed(4)} ${symbol}`;
    }
    const CustomOption = (props) => {
        const { innerProps, innerRef } = props;
        return (
            <div ref={innerRef} {...innerProps} className={props.isSelected ? styles.custom_option_selected : styles.custom_option}>
                <div className={styles.option} style={{ color: props.data.color, backgroundColor: props.data.background, width: 'auto' }}>
                    {props.data.label}
                </div>
            </div>)
    }

    const handleImgError = (e) => {
        e.target.src = altIcon; // Подставляем альтернативную иконку при ошибке
    };
    const DropdownIndicator = ({ children, ...props }) => (
        <components.DropdownIndicator {...props}>
            <div className={styles.single_value_container}>
                <div className={styles.custom_icon}>
                    <svg width={"1.7vh"} height={"1.6vh"} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.5003 6L8.83366 10L4.16699 6" stroke="#A0A0AB" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                {children}
            </div>
        </components.DropdownIndicator>
    );
    return (
        <Drawer
          open={open}
          anchor={"bottom"}
          onClose={toggleDrawer(false)}
          PaperProps={{
            className: styles.paper,
          }}
        >
        <div className={styles.account_drawer}>
            <div className={styles.top_drawer}>
                <div className={styles.drawer_header}>
                {assetsLogo[
  AssetsList?.find((val) => val.Title === activeOrder?.Title)?.Ticker
] ? (
  <div
    dangerouslySetInnerHTML={{
      __html: assetsLogo[
        AssetsList?.find((val) => val.Title === activeOrder?.Title)?.Ticker
      ],
    }}
    style={{
      width: "32px",
      height: "32px",
      overflow: "hidden",
    }}
  />
) : assetsLogo[
    AssetsList?.find((val) => val.Title === activeOrder?.Asset)?.Ticker
  ] ? (
  <div
    dangerouslySetInnerHTML={{
      __html: assetsLogo[
        AssetsList?.find((val) => val.Title === activeOrder?.Asset)?.Ticker
      ],
    }}
    style={{
      width: "32px",
      height: "32px",
      overflow: "hidden",
    }}
  />
) : (
  <img
    src={`https://cdn.jsdelivr.net/gh/vadimmalykhin/binance-icons/crypto/${
      activeOrder?.Subtitle
        ? activeOrder?.Subtitle.slice(1, 4).toLowerCase()
        : activeOrder?.SubTitle?.slice(1, 4).toLowerCase()
    }.svg`}
    alt="icon"
    onError={handleImgError}
    style={{
      width: "32px",
      height: "32px",
      borderRadius: "50%",
    }}
  />
)}
                        {activeOrder?.Subtitle?
                            account?.AccountCurrency == "EUR"
                            ?
                            activeOrder?.Subtitle?.slice(1,-1).replace("/USD","/EUR")
                            :
                            activeOrder?.Subtitle?.slice(1,-1)
                        :
                        account?.AccountCurrency == "EUR"
                            ?
                            activeOrder?.SubTitle?.slice(1,-1).replace("/USD","/EUR")
                            :
                            activeOrder?.SubTitle?.slice(1,-1)
                        }
                        {activeOrder?.Status && (
                            <span className={`${styles.type} ${activeOrder?.Status?.includes('BUY') ? styles.buy :activeOrder?.Status?.includes('SELL')? styles.sell :styles.simulate}`}>{activeOrder?.Status}</span>
                        )}
                        {
                            activeOrder?.OrderType &&(
                                <span className={`${styles.type} ${activeOrder?.OrderType?.includes('BUY') ? styles.buy :activeOrder?.OrderType?.includes('SELL')? styles.sell :styles.simulate}`}>{activeOrder?.OrderType}</span>
                            )
                        }
                </div>
                <div className={styles.drawer_body}>
                    <div className={styles.values}>
                        <label>{t('Position')}</label>
                        <div className={styles.value}>{activeOrder?.Id}</div>
                    </div>
                    <div className={styles.values}>
                        <label>{t('Date')}</label>
                        <div className={styles.value}>{moment(activeOrder?.TimeInForceDate).format('YYYY-MM-DD HH:mm')}</div>
                    </div>
                    {activeOrder?.EndPrice?<div className={`${styles.values} ${styles.tool}`}>
                        <label>{t('Instrument')}</label>
                        <div className={styles.value}>
                        {assetsLogo12px[
  AssetsList?.find((val) => val.Title === activeOrder?.Title)?.Ticker
] ? (
  <div
    dangerouslySetInnerHTML={{
      __html: assetsLogo12px[
        AssetsList?.find((val) => val.Title === activeOrder?.Title)?.Ticker
      ],
    }}
    style={{
      width: "16px",
      height: "16px",
      overflow: "hidden",
    }}
  />
) : assetsLogo12px[
    AssetsList?.find((val) => val.Title === activeOrder?.Asset)?.Ticker
  ] ? (
  <div
    dangerouslySetInnerHTML={{
      __html: assetsLogo12px[
        AssetsList?.find((val) => val.Title === activeOrder?.Asset)?.Ticker
      ],
    }}
    style={{
      width: "16px",
      height: "16px",
      overflow: "hidden",
    }}
  />
) : (
  <img
    src={`https://cdn.jsdelivr.net/gh/vadimmalykhin/binance-icons/crypto/${
      activeOrder?.Subtitle
        ? activeOrder?.Subtitle.slice(1, 4).toLowerCase()
        : activeOrder?.SubTitle?.slice(1, 4).toLowerCase()
    }.svg`}
    alt="icon"
    onError={handleImgError}
    style={{
      width: "16px",
      height: "16px",
      borderRadius: "50%",
    }}
  />
)}{activeOrder?.Subtitle?
                                account?.AccountCurrency == "EUR"
                                ?
                                activeOrder?.Subtitle?.slice(1,-1).replace("/USD","/EUR")
                                :
                                activeOrder?.Subtitle?.slice(1,-1)
                                :
                                account?.AccountCurrency == "EUR"
                                ?
                                activeOrder?.SubTitle?.slice(1,-1).replace("/USD","/EUR")
                                :
                                activeOrder?.SubTitle?.slice(1,-1)
                                }
                        </div>
                    </div>:<div className={`${styles.values} ${styles.tool}`}>
                        <label>{t('Instrument')}</label>
                        <div className={styles.value}>
                        {assetsLogo12px[
  AssetsList?.find((val) => val.Title === activeOrder?.Title)?.Ticker
] ? (
  <div
    dangerouslySetInnerHTML={{
      __html: assetsLogo12px[
        AssetsList?.find((val) => val.Title === activeOrder?.Title)?.Ticker
      ],
    }}
    style={{
      width: "16px",
      height: "16px",
      overflow: "hidden",
    }}
  />
) : assetsLogo12px[
    AssetsList?.find((val) => val.Title === activeOrder?.Asset)?.Ticker
  ] ? (
  <div
    dangerouslySetInnerHTML={{
      __html: assetsLogo12px[
        AssetsList?.find((val) => val.Title === activeOrder?.Asset)?.Ticker
      ],
    }}
    style={{
      width: "16px",
      height: "16px",
      overflow: "hidden",
    }}
  />
) : (
  <img
    src={`https://cdn.jsdelivr.net/gh/vadimmalykhin/binance-icons/crypto/${
      activeOrder?.Subtitle
        ? activeOrder?.Subtitle.slice(1, 4).toLowerCase()
        : activeOrder?.SubTitle?.slice(1, 4).toLowerCase()
    }.svg`}
    alt="icon"
    onError={handleImgError}
    style={{
      width: "16px",
      height: "16px",
      borderRadius: "50%",
    }}
  />
)}{activeOrder?.Subtitle?
                                account?.AccountCurrency == "EUR"
                                ?
                                activeOrder?.Subtitle?.slice(1,-1).replace("/USD","/EUR")
                                :
                                activeOrder?.Subtitle?.slice(1,-1)
                                :
                                account?.AccountCurrency == "EUR"
                                ?
                                activeOrder?.SubTitle?.slice(1,-1).replace("/USD","/EUR")
                                :
                                activeOrder?.SubTitle?.slice(1,-1)
                                }
                        </div>
                    </div>}
                    {/* {!activeOrder?.EndPrice ? <div className={styles.values}>
                        <label>{t('Type')}</label>
                        <div className={styles.value_type}>{activeOrder?.Status && (
                            <span className={`${styles.type} ${activeOrder?.Status?.includes('BUY') ? styles.buy :activeOrder?.Status?.includes('SELL')? styles.sell :styles.simulate}`}>{activeOrder?.Status}</span>
                        )}</div>
                    </div>:<div className={styles.values}>
                        <label>{t('Type')}</label>
                        <div className={styles.value}>
                                <span className={`${styles.type} ${activeOrder?.OrderType?.includes('BUY') ? styles.buy :activeOrder?.OrderType?.includes('SELL')? styles.sell :styles.simulate}`}>{activeOrder?.OrderType}</span>
                           </div>
                    </div>} */}
                    <div className={styles.values}>
                        <label>{t('Leverage')}</label>
                        <div className={styles.value} style={{color:'#E9770E',textTransform:'uppercase'}}>x{activeOrder?.Leverage}</div>
                    </div>
                    {!activeOrder?.EndPrice && <div className={styles.values}>
                        <label>{t('Margin')}</label>
                        <div className={styles.value}>
                        {
                            account?.AccountCurrency == "EUR"
                                ?
                                "€"
                                :
                                "$"
                        }{activeOrder?.OpenWhen?parseFloat(((activeOrder?.OpenWhen * (account?.AccountCurrency == "EUR" ? eurToUsd : 1)) * activeOrder?.Count * activeOrder?.LotSize) / activeOrder?.Leverage).toFixed(5):parseFloat(activeOrder?.Price * (account?.AccountCurrency == "EUR" ? eurToUsd : 1))?.toFixed(5)}
                        </div>
                    </div>}
                    <div className={styles.values}>
                        <label>{t('EntryPrice')}</label>
                        <div className={styles.value}>
                        {
                            account?.AccountCurrency == "EUR"
                                ?
                                "€"
                                :
                                "$"
                        }{activeOrder?.OpenWhen?(activeOrder?.OpenWhen * (account?.AccountCurrency == "EUR"? eurToUsd : 1)).toFixed(5):parseFloat(activeOrder?.StartPrice * (account?.AccountCurrency == "EUR"? eurToUsd : 1)).toFixed(5)}</div>
                    </div>
                    {activeOrder?.EndPrice && <div className={styles.values}>
                        <label>{t('EndPrice')}</label>
                        <div className={styles.value}>
                        {
                            account?.AccountCurrency == "EUR"
                                ?
                                "€"
                                :
                                "$"
                        }{parseFloat(activeOrder?.EndPrice* (account?.AccountCurrency == "EUR"? eurToUsd : 1)).toFixed(5)}</div>
                    </div>}
                    <div className={`${styles.values} ${styles.tp_sl}`}>
                        <label>{t('Take-Profit')}</label>
                        <div className={styles.value}>
                            <span className={styles.tp}>{activeOrder?.TakeProfit?activeOrder?.TakeProfit>1?parseFloat(activeOrder?.TakeProfit * (account?.AccountCurrency == "EUR"? eurToUsd : 1)).toFixed(2):parseFloat(activeOrder?.TakeProfit * (account?.AccountCurrency == "EUR"? eurToUsd : 1)).toFixed(5):0}
                        {
                            account?.AccountCurrency == "EUR"
                                ?
                                "€"
                                :
                                "$"
                        }</span>
                        </div>
                    </div>
                    <div className={`${styles.values} ${styles.tp_sl}`}>
                        <label>{t('Stop-Loss')}</label>
                        <div className={styles.value}>
                            <span className={styles.sl}>{activeOrder?.StopLoss?activeOrder?.StopLoss>1?parseFloat(activeOrder?.StopLoss * (account?.AccountCurrency == "EUR"? eurToUsd : 1)).toFixed(2):parseFloat(activeOrder?.StopLoss * (account?.AccountCurrency == "EUR"? eurToUsd : 1)).toFixed(5):0}
                        {
                            account?.AccountCurrency == "EUR"
                                ?
                                "€"
                                :
                                "$"
                        }</span>
                        </div>
                    </div>
                    <div className={styles.values}>
                        <label>{t('Liquidation')}</label>
                        <div className={styles.value} style={{color:'#F63D68'}}>                            
                            {
                                getLiquidationPrice(activeOrder)
                            }
                        </div>
                    </div>
                    {marketPrice ? 
                    <>
                    {!activeOrder?.OpenWhen && <div className={styles.values}>
                        <label>{t('Profit')}</label>
                        <div className={styles.value} style={{color:parseFloat(activeOrder?.Profit)> 0?'#00B4A0':"#F63D68"}}>
                            {activeOrder?.Profit > 0? '+' : ''}
                            {parseFloat(`${activeOrder?.Profit}`.slice(1)) > 1 
                            ? ' ' + (activeOrder?.Profit < 0 
                            ? '- ' +  parseFloat((`${activeOrder?.Profit}`.slice(1)) * (account?.AccountCurrency == "EUR"? eurToUsd : 1)).toFixed(2)
                            :parseFloat(activeOrder?.Profit * (account?.AccountCurrency == "EUR"? eurToUsd : 1))?.toFixed(2)) 
                            : ' ' + (activeOrder?.Profit < 0 ? '- ' +  parseFloat((`${activeOrder?.Profit}`.slice(1))* (account?.AccountCurrency == "EUR"? eurToUsd : 1)).toFixed(5)
                            :parseFloat(activeOrder?.Profit * (account?.AccountCurrency == "EUR"? eurToUsd : 1))?.toFixed(5))}
                            {
                                account?.AccountCurrency == "EUR"
                                    ?
                                    " €"
                                    :
                                    " $"
                            }
                        </div>
                    </div>}
                    </>
                    :<div className={styles.values}>
                        <label>{t('Profit')}</label>
                        <div className={styles.value} style={{color:parseFloat(activeOrder?.Profit)> 0?'#00B4A0':"#F63D68"}}>
                            {activeOrder?.Profit > 0? '+' : ''}
                            {parseFloat(`${activeOrder?.Profit}`.slice(1)) > 1 
                            ? ' ' + (activeOrder?.Profit < 0 
                            ? '- ' +  parseFloat((`${activeOrder?.Profit}`.slice(1)) * (account?.AccountCurrency == "EUR"? eurToUsd : 1)).toFixed(2)
                            :parseFloat(activeOrder?.Profit * (account?.AccountCurrency == "EUR"? eurToUsd : 1))?.toFixed(2)) 
                            : ' ' + (activeOrder?.Profit < 0 ? '- ' +  parseFloat((`${activeOrder?.Profit}`.slice(1))* (account?.AccountCurrency == "EUR"? eurToUsd : 1)).toFixed(5)
                            :parseFloat(activeOrder?.Profit * (account?.AccountCurrency == "EUR"? eurToUsd : 1))?.toFixed(5))}
                            {
                                account?.AccountCurrency == "EUR"
                                    ?
                                    " €"
                                    :
                                    " $"
                            }
                        </div>
                    </div>
                    }
                    
                </div>
            </div>
            <div className={styles.drawer_footer}>
                <div onClick={toggleDrawer(false)}>{t('Close')}</div>
            </div>
        </div>
        </Drawer>
    )
}
