import React, { useEffect, useMemo, useState } from 'react'
import styles from './Transactions.module.scss'
import { useTranslation } from 'react-i18next'
import { colourStylesStatus } from '../../components/SelectStatusStyles';
import { colourStylesCurrency } from '../../components/SelectCurrencyStyles';
import Select, { components } from 'react-select';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import moment from 'moment';
import OrdersTable from '../../components/OrdersTable';
export default function Transactions({ theme, eurToUsd,account,setSnackType,setSnackMessage,handleClick }) {
  const { t, i18n } = useTranslation();
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedType, setSelectedType] = useState(null);

  useEffect(() => {
    if(account)
    axios
      .get(
        `${process.env.REACT_APP_test_url}/api/transactions/crm/${account?.Id}`,
        {
          headers: {
            Authorization: 'Bearer ' + secureLocalStorage.getItem('token'),
          },
        }
      )
      .then((result) => {
        const deposits = result.data?.deposits || [];
        const withdrawals = result.data?.withdrawals || [];
        const allTransactions = [...deposits, ...withdrawals];

        // Сортировка по дате (новые сначала)
        allTransactions.sort((a, b) => new Date(b.Date) - new Date(a.Date));

        setTransactions(allTransactions);
        setFilteredTransactions(allTransactions);
      })
      .catch((e) => console.log(e));
  }, [account]);



  const columns = useMemo(
    () => [
      {
        accessorKey: 'Date',
        header: t('Date'),
        size: 160,
        grow: 1,
        Header: () => (
          <div className={styles.header_cell}>
            <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Date')}</span>
            <div className={styles.sort_icon}>
              <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
          </div>
        ),
        Cell: ({ row }) => {
          return (
            <span>
              {moment(row.original.Date).format("YYYY-MM-DD HH:mm")}
            </span>
          )
        },
      },
      {
        accessorKey: 'Type',
        header: t('Type'),
        size: 70,
        grow: 1,
        Header: () => (
          <div className={styles.header_cell}>
            <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Type')}</span>
            <div className={styles.sort_icon}>
              <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
          </div>
        ),
        Cell: ({ row }) => {
          return (
            <span style={row.original.Type === 'Deposit'
              ? { color: '#00B4A0', borderRadius: '9999px', padding: '0.4vh 0.8vh', width: 'max-content', background: 'rgba(0, 180, 160, 0.12)', display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '0.8vh' }
              : { color: '#E9770E', borderRadius: '9999px', padding: '0.4vh 0.8vh', width: 'max-content', background: 'rgba(233, 119, 14, 0.08)', display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '0.8vh' }}>
              {row.original.Type === 'Deposit' ?
                <svg xmlns="http://www.w3.org/2000/svg" width={'1.7vh'} height={'1.6vh'} viewBox="0 0 17 16" fill="none">
                  <path d="M13.3812 13.3335V9.3335M13.3812 9.3335L14.7145 10.6668M13.3812 9.3335L12.0479 10.6668" stroke="#00B4A0" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M15.3809 7.99984C15.3809 5.48568 15.3809 4.2286 14.5999 3.44755C13.8188 2.6665 12.5618 2.6665 10.0476 2.6665H7.38094C4.86678 2.6665 3.6097 2.6665 2.82866 3.44755C2.04761 4.2286 2.04761 5.48568 2.04761 7.99984C2.04761 10.514 2.04761 11.7711 2.82866 12.5521C3.6097 13.3332 4.86678 13.3332 7.38094 13.3332H10.0476" stroke="#00B4A0" strokeLinecap="round" />
                  <path d="M7.38102 10.6665H4.71436" stroke="#00B4A0" strokeLinecap="round" />
                  <path d="M9.38119 10.6665H9.04785" stroke="#00B4A0" strokeLinecap="round" />
                  <path d="M2.04761 6.6665L15.3809 6.6665" stroke="#00B4A0" strokeLinecap="round" />
                </svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg" width={'1.7vh'} height={'1.6vh'} viewBox="0 0 17 16" fill="none">
                  <path d="M13.3812 9.3335V13.3335M13.3812 13.3335L14.7145 12.0002M13.3812 13.3335L12.0479 12.0002" stroke="#E9770E" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M15.3809 7.99984C15.3809 5.48568 15.3809 4.2286 14.5999 3.44755C13.8188 2.6665 12.5618 2.6665 10.0476 2.6665H7.38094C4.86678 2.6665 3.6097 2.6665 2.82866 3.44755C2.04761 4.2286 2.04761 5.48568 2.04761 7.99984C2.04761 10.514 2.04761 11.7711 2.82866 12.5521C3.6097 13.3332 4.86678 13.3332 7.38094 13.3332H10.0476" stroke="#E9770E" strokeLinecap="round" />
                  <path d="M7.38102 10.6665H4.71436" stroke="#E9770E" strokeLinecap="round" />
                  <path d="M9.38119 10.6665H9.04785" stroke="#E9770E" strokeLinecap="round" />
                  <path d="M2.04761 6.6665L15.3809 6.6665" stroke="#E9770E" strokeLinecap="round" />
                </svg>
              }
              {row.original.Type}
            </span>
          )
        },
      },
      {
        accessorKey: 'Sum',
        header: t('Amount'),
        size: 110,
        grow: 1,
        Header: () => (
          <div className={styles.header_cell}>
            <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Amount')}</span>
            <div className={styles.sort_icon}>
              <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
          </div>
        ),
        Cell: ({ row }) => {
          return (
            <span>
              {account?.AccountCurrency == "EUR" ? "€" : "$"} {parseFloat(row.original.Sum * (account?.AccountCurrency == "EUR" ? eurToUsd : 1)).toFixed(2)}
            </span>
          )
        },
      },
      {
        accessorKey: 'Status',
        header: t('Status'),
        size: 100,
        grow: 1,
        Header: () => (
          <div className={styles.header_cell}>
            <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Status')}</span>
            <div className={styles.sort_icon}>
              <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
          </div>
        ),
        Cell: ({ row }) => {
          const status = row.original?.ApplicationId
          ?
            ["Submitted","Expectation"].includes(row.original?.Status)?
            "Pending"
            :"Completed"
          :"Completed";
          return (
            <span style={{ color: '#52A5E5', padding: '4px 8px', borderRadius: '9999px', fontSize: '1.2vh', background: 'rgba(82, 165, 229, 0.08)' }}>
              {status}
            </span>
          )
        },
      },
    ],
    [i18n.language, eurToUsd, selectedType, searchTerm, transactions],
  )

  // Функция для фильтрации транзакций
  const filterTransactions = () => {
    let filtered = transactions;

    // Фильтр по типу транзакции
    if (selectedType) {
      filtered = filtered.filter(transaction => transaction.Type === selectedType.label);
    }

    // Фильтр по поисковому запросу (добавили проверку по сумме)
    if (searchTerm) {
      filtered = filtered.filter(transaction =>
        transaction.Type.toLowerCase().includes(searchTerm.toLowerCase()) ||
        transaction.Sum.toString().includes(searchTerm) ||
        parseFloat(transaction.Sum * (account?.AccountCurrency == "EUR" ? eurToUsd : 1)).toFixed(2).includes(searchTerm) || // Добавляем проверку, если значение с точностью до двух знаков после запятой
        moment(transaction.Date).format("YYYY-MM-DD HH:mm").includes(searchTerm)
      );
    }

    setFilteredTransactions(filtered);
  };

  // Запуск фильтрации при изменении критериев
  useEffect(() => {
    filterTransactions();
  }, [searchTerm, selectedType, transactions]);

  const CustomOption = (props) => {
    const { innerProps, innerRef } = props;
    return (
      <div ref={innerRef} {...innerProps} className={props.isSelected ? styles.custom_option_selected : styles.custom_option}>
        <div className={styles.option} style={{ color: props.data.color, backgroundColor: props.data.background, width: 'auto' }}>
          {props.data.label}
        </div>
      </div>)
  }

  const DropdownIndicator = ({ children, ...props }) => (
    <components.DropdownIndicator {...props}>
      <div className={styles.single_value_container}>
        <div className={styles.custom_icon}>
          <svg width={"1.7vh"} height={"1.6vh"} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5003 6L8.83366 10L4.16699 6" stroke="#A0A0AB" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        {children}
      </div>
    </components.DropdownIndicator>
  );
  return (
    <div className={styles.transactions}>
      <div className={styles.transactions_title}>
        <svg xmlns="http://www.w3.org/2000/svg" width={'1.6vh'} height={'1.6vh'} viewBox="0 0 16 16" fill="none">
          <path d="M6.33333 1.33398C5.78105 1.33398 5.33333 1.7817 5.33333 2.33398V3.00065C5.33333 3.55294 5.78105 4.00065 6.33333 4.00065H9.66667C10.219 4.00065 10.6667 3.55294 10.6667 3.00065V2.33398C10.6667 1.7817 10.219 1.33398 9.66667 1.33398H6.33333Z" fill="#FCFCFC" />
          <path fillRule="evenodd" clipRule="evenodd" d="M4.33333 2.69173C3.49473 2.73878 2.96532 2.87241 2.58579 3.25195C2 3.83773 2 4.78054 2 6.66616V10.6662C2 12.5518 2 13.4946 2.58579 14.0804C3.17157 14.6662 4.11438 14.6662 6 14.6662H10C11.8856 14.6662 12.8284 14.6662 13.4142 14.0804C14 13.4946 14 12.5518 14 10.6662V6.66616C14 4.78054 14 3.83773 13.4142 3.25195C13.0347 2.87241 12.5053 2.73878 11.6667 2.69173V3.00065C11.6667 4.10522 10.7712 5.00065 9.66667 5.00065H6.33333C5.22876 5.00065 4.33333 4.10522 4.33333 3.00065V2.69173ZM4.66667 9.16732C4.39052 9.16732 4.16667 9.39118 4.16667 9.66732C4.16667 9.94346 4.39052 10.1673 4.66667 10.1673H10C10.2761 10.1673 10.5 9.94346 10.5 9.66732C10.5 9.39118 10.2761 9.16732 10 9.16732H4.66667ZM4.66667 11.5007C4.39052 11.5007 4.16667 11.7245 4.16667 12.0007C4.16667 12.2768 4.39052 12.5007 4.66667 12.5007H8.33333C8.60948 12.5007 8.83333 12.2768 8.83333 12.0007C8.83333 11.7245 8.60948 11.5007 8.33333 11.5007H4.66667Z" fill="#FCFCFC" />
        </svg>
        {t('Transactions')}
      </div>
      <div className={styles.transactions_body}>

        <div className={styles.table_header}>
          <div className={styles.left_side}>
            <svg xmlns="http://www.w3.org/2000/svg" width={'1.6vh'} height={'1.6vh'} viewBox="0 0 16 16" fill="none">
              <g clipPath="url(#clip0_339_5008)">
                <circle cx="7.66659" cy="7.66634" r="6.33333" stroke="#51525C" />
                <path d="M13.3333 13.333L14.6666 14.6663" stroke="#51525C" strokeLinecap="round" />
              </g>
              <defs>
                <clipPath id="clip0_339_5008">
                  <rect width={16} height={16} fill="white" />
                </clipPath>
              </defs>
            </svg>
            <input placeholder={t('Search')} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
          </div>
          <div className={styles.right_side}>
            <div className={styles.select}>
              <Select
                classNamePrefix="custom_select"
                className={`${styles.select_select} custom-select-container`}
                placeholder={'Type'}
                menuPlacement='auto'
                options={[{ value: 1, label: 'Deposit', color: '#00B4A0', background: 'rgba(0, 180, 160, 0.12)' }, { value: 2, label: 'Withdrawal', color: '#E9770E', background: 'rgba(233, 119, 14, 0.08)' }]}
                styles={colourStylesStatus(theme, 'Sale')}
                components={{ DropdownIndicator, IndicatorSeparator: () => null, Option: CustomOption }}
                onChange={(selectedOption) => setSelectedType(selectedOption)}
                value={selectedType}
                isClearable
              />
            </div>
            {/* <div className={styles.select}>
                            <Select
                                classNamePrefix="custom_select"
                                className={`${styles.select_select} custom-select-container`}
                                placeholder={'Type'}
                                menuPlacement='auto'
                                options={[{ value: 1, label: 'USD' }, { value: 2, label: 'EUR' }]}
                                styles={colourStylesCurrency(theme)}
                                components={{ DropdownIndicator, IndicatorSeparator: () => null, Option: CustomOption }}
                                onChange={(selectedOption) => console.log(selectedOption, "StatusId", "All")}
                            />
                        </div> */}
          </div>
        </div>
        <div className={styles.table}>
          <OrdersTable height={'a'} columns={columns} data={filteredTransactions} theme={theme} />
        </div>
        <div className={styles.mobile_transactions}>
          <div className={styles.transactions_list}>
            {transactions.map(el =>
              <div className={styles.transaction} key={el.Id}>
                <div className={styles.type_status}>
                  <div className={`${styles.type} ${el.Type === 'Withdrawal' ? styles.with : ""}`}>
                    {el.Type === 'Withdrawal' ?
                      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                        <path d="M13.3812 9.33301V13.333M13.3812 13.333L14.7145 11.9997M13.3812 13.333L12.0479 11.9997" stroke="#E9770E" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M15.3809 8.00033C15.3809 5.48617 15.3809 4.22909 14.5999 3.44804C13.8188 2.66699 12.5618 2.66699 10.0476 2.66699H7.38094C4.86678 2.66699 3.6097 2.66699 2.82866 3.44804C2.04761 4.22909 2.04761 5.48617 2.04761 8.00033C2.04761 10.5145 2.04761 11.7716 2.82866 12.5526C3.6097 13.3337 4.86678 13.3337 7.38094 13.3337H10.0476" stroke="#E9770E" stroke-linecap="round" />
                        <path d="M7.38102 10.667H4.71436" stroke="#E9770E" stroke-linecap="round" />
                        <path d="M9.38119 10.667H9.04785" stroke="#E9770E" stroke-linecap="round" />
                        <path d="M2.04761 6.66699L15.3809 6.66699" stroke="#E9770E" stroke-linecap="round" />
                      </svg>
                      : <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                        <path d="M13.3812 13.333V9.33301M13.3812 9.33301L14.7145 10.6663M13.3812 9.33301L12.0479 10.6663" stroke="#00B4A0" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M15.3809 8.00033C15.3809 5.48617 15.3809 4.22909 14.5999 3.44804C13.8188 2.66699 12.5618 2.66699 10.0476 2.66699H7.38094C4.86678 2.66699 3.6097 2.66699 2.82866 3.44804C2.04761 4.22909 2.04761 5.48617 2.04761 8.00033C2.04761 10.5145 2.04761 11.7716 2.82866 12.5526C3.6097 13.3337 4.86678 13.3337 7.38094 13.3337H10.0476" stroke="#00B4A0" stroke-linecap="round" />
                        <path d="M7.38102 10.667H4.71436" stroke="#00B4A0" stroke-linecap="round" />
                        <path d="M9.38119 10.667H9.04785" stroke="#00B4A0" stroke-linecap="round" />
                        <path d="M2.04761 6.66699L15.3809 6.66699" stroke="#00B4A0" stroke-linecap="round" />
                      </svg>
                    }
                    {el.Type}
                  </div>
                  <div className={styles.status}>{el?.ApplicationId
                    ?
                      ["Submitted","Expectation"].includes(el?.Status)?
                      "Pending"
                      :t('Completed')
                    :t('Completed')}
                  </div>
                </div>
                <div className={styles.account_amount}>
                  <div className={styles.account}>
                    <label htmlFor="">{t('Account')}</label>
                    <p>{account?.Id}</p>
                  </div>
                  <div className={styles.amount}>
                    <label htmlFor="">{t('Amount')}</label>
                    <p className={`${el.Type === 'Withdrawal' ? styles.with : ''}`}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <g clip-path="url(#clip0_575_44989)">
                          <path d="M7.87878 15.5202C12.0514 15.5202 15.4082 12.1634 15.4082 7.99084C15.4082 3.81826 12.0514 0.461426 7.87878 0.461426C3.7062 0.461426 0.349365 3.81826 0.349365 7.99084C0.349365 12.1634 3.7062 15.5202 7.87878 15.5202Z" fill="#00B4A0" />
                          <path d="M9.9492 9.18271C9.9492 8.08469 9.29039 7.70822 7.97276 7.55138C7.03159 7.42587 6.84336 7.17491 6.84336 6.73564C6.84336 6.29638 7.15711 6.0141 7.78452 6.0141C8.34922 6.0141 8.66297 6.20233 8.8198 6.67292C8.8512 6.76704 8.94532 6.82976 9.03943 6.82976H9.54134C9.66685 6.82976 9.76097 6.73564 9.76097 6.6102V6.5788C9.63545 5.88858 9.07076 5.35527 8.34922 5.29255V4.53961C8.34922 4.41409 8.25511 4.31997 8.09827 4.28857H7.62769C7.50217 4.28857 7.40806 4.38269 7.37666 4.53961V5.26115C6.4355 5.38667 5.83948 6.0141 5.83948 6.79844C5.83948 7.83374 6.4669 8.24153 7.78452 8.39844C8.66297 8.55528 8.94532 8.74352 8.94532 9.24551C8.94532 9.74749 8.50606 10.0926 7.91004 10.0926C7.09431 10.0926 6.81196 9.74742 6.71785 9.27683C6.68653 9.15139 6.59241 9.08859 6.49829 9.08859H5.96492C5.83948 9.08859 5.74536 9.18271 5.74536 9.30823V9.33962C5.8708 10.1239 6.37278 10.6886 7.40806 10.8455V11.5985C7.40806 11.7239 7.50218 11.818 7.65901 11.8494H8.12959C8.25511 11.8494 8.34922 11.7553 8.38062 11.5985V10.8455C9.32178 10.6886 9.9492 10.0298 9.9492 9.18271Z" fill="white" />
                          <path d="M6.27942 12.4773C3.83236 11.5989 2.57743 8.86948 3.48729 6.45374C3.95787 5.1361 4.99317 4.1322 6.27942 3.66161C6.40493 3.59889 6.46765 3.50477 6.46765 3.34786V2.90867C6.46765 2.78315 6.40493 2.68904 6.27942 2.65771C6.24802 2.65771 6.1853 2.65771 6.1539 2.68904C3.17354 3.63021 1.54214 6.79889 2.48331 9.77926C3.04802 11.5361 4.39706 12.8851 6.1539 13.4498C6.27942 13.5126 6.40493 13.4498 6.43626 13.3243C6.46765 13.293 6.46765 13.2616 6.46765 13.1989V12.7596C6.46765 12.6655 6.37354 12.5401 6.27942 12.4773ZM9.60493 2.68904C9.47942 2.62632 9.3539 2.68904 9.32258 2.81455C9.29118 2.84595 9.29118 2.87727 9.29118 2.94007V3.37926C9.29118 3.50477 9.3853 3.63021 9.47942 3.69301C11.9265 4.57139 13.1814 7.3008 12.2715 9.71654C11.801 11.0342 10.7657 12.0381 9.47942 12.5087C9.3539 12.5714 9.29118 12.6655 9.29118 12.8224V13.2616C9.29118 13.3871 9.3539 13.4812 9.47942 13.5126C9.51082 13.5126 9.57354 13.5126 9.60493 13.4812C12.5853 12.5401 14.2167 9.37139 13.2755 6.39102C12.7108 4.60279 11.3304 3.25374 9.60493 2.68904Z" fill="white" />
                        </g>
                        <defs>
                          <clipPath id="clip0_575_44989">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      {el.Type === 'Withdrawal' ? '-' : '+'}
                      {
                        account?.AccountCurrency == "EUR"
                          ?
                          "€"
                          :
                          "$"
                      }
                      {parseFloat(el.Sum* (account?.AccountCurrency == "EUR" ? eurToUsd : 1)).toFixed(1)}
                    </p>
                  </div>
                </div>
                <div className={styles.id_date}>
                  <div className={styles.id}>
                    ID {el.Id}
                  </div>
                  <div className={styles.date}>
                    {moment(el.Date).format("YYYY-MM-DD HH:mm")}
                  </div>
                </div>
              </div>)
            }
          </div>
        </div>
      </div>
    </div>
  )
}
